import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { Input } from "../ui/input";
import { IoMdSearch } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Location_suggestions_input = ({
  setAddress,
  setPostalCode,
  setDepartment,
  setCountry,
}) => {
  const [localAddress, setLocalAddress] = useState("");
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [hasSelectedSuggestion, setHasSelectedSuggestion] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const loadGoogleMapsScript = (callback) => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.body.appendChild(script);
    } else {
      callback();
    }
  };

  useEffect(() => {
    loadGoogleMapsScript(() => setIsGoogleLoaded(true));

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = async (val, placeId) => {
    setHasSelectedSuggestion(true);
    setIsDropdownOpen(false);

    if (placeId) {
      try {
        const results = await geocodeByPlaceId(placeId);
        if (results.length > 0) {
          const components = results[0].address_components;

          const streetNumber =
            components.find((c) => c.types.includes("street_number"))
              ?.long_name || "";

          const city =
            components.find((c) => c.types.includes("locality"))?.long_name ||
            "";

          const street =
            components.find((c) => c.types.includes("route"))?.long_name || "";

          const countryName =
            components.find((c) => c.types.includes("country"))?.long_name ||
            "";

          const code = components.find((c) =>
            c.types.includes("postal_code")
          )?.long_name;
          const departmentName = components.find((c) =>
            c.types.includes("administrative_area_level_2")
          )?.long_name;

          const formattedAddress = `${streetNumber} ${street}, ${city}`;
          setLocalAddress(formattedAddress);
          setAddress(formattedAddress);
          setPostalCode(code || "");
          setDepartment(departmentName || "");
          setCountry(countryName || "");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du lieu :", error);
      }
    }
  };

  const handleBlur = () => {
    if (!hasSelectedSuggestion && localAddress.trim() !== "") {
      setAddress(localAddress);
      setPostalCode("");
      setDepartment("");
      setCountry("");
    }
    setHasSelectedSuggestion(false);
  };

  if (!isGoogleLoaded) return null;

  return (
    <PlacesAutocomplete
      value={localAddress}
      onChange={(val) => {
        setLocalAddress(val);
        setHasSelectedSuggestion(false);
        setIsDropdownOpen(true);
      }}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions: { country: ["fr", "be", "ch"] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        return (
          <div className="relative w-full" ref={dropdownRef}>
            <span className="absolute inset-y-0 left-1 flex items-center pl-2 pointer-events-none">
              <IoMdSearch />
            </span>
            <Input
              {...getInputProps({ placeholder: "Saisissez votre adresse..." })}
              onFocus={() => setIsDropdownOpen(true)}
              onBlur={handleBlur}
              className="pl-9 w-full"
            />

            {isDropdownOpen && suggestions.length > 0 && (
              <div className="absolute top-full left-0 w-full bg-white border border-gray-200 mt-1 z-10 text-sm shadow-lg rounded-md">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                    className="flex items-center p-2 hover:bg-gray-200 cursor-pointer"
                  >
                    <IoLocationSharp className="mr-2" />
                    <span>{suggestion.description}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default Location_suggestions_input;
