import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [infos_archetypes, setInfos_Archetypes] = useState([]);
  const [stats_archetypes, setStats_Archetypes] = useState([]);
  const [user_archetypes, setUser_archetypes] = useState([]);

  const [inspirationData, setInspirationData] = useState(null);
  const [currentSessionCoach, setCurrentSessionCoach] = useState(null);
  const [activeMenu, setActiveMenu] = useState("archetypes");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState(null); // 'conversation' ou 'concept'

  const [userID, setUserID] = useState("");

  return (
    <GlobalContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        infos_archetypes,
        setInfos_Archetypes,
        stats_archetypes,
        setStats_Archetypes,
        user_archetypes,
        setUser_archetypes,
        inspirationData,
        setInspirationData,
        currentSessionCoach,
        setCurrentSessionCoach,
        messages,
        setMessages,
        loading,
        setLoading,
        loadingType,
        setLoadingType,
        userID,
        setUserID,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
