import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { CgRemoveR } from "react-icons/cg";
import axios from "axios";

const SessionInspirationCard = ({ session, isActive, onClick, onDelete }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (isActive && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isActive]);

  const handleDelete = async () => {
    try {
      await axios.post("http://localhost:5001/coach/session/remove", {
        sessionId: session._id,
      });
      onDelete(session._id);
    } catch (error) {
      console.error("Error deleting session:", error);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className={`session-inspiration-card ${isActive ? "active" : ""}`}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      <div className="session-inspiration-img">
        <img
          src={session.messages[0].content.obj.inspiration.url_pic}
          alt={session.messages[0].content.obj.inspiration.title}
        />
      </div>
      <div className="session-inspiration-infos">
        <h2>{session.messages[0].content.obj.inspiration.title}</h2>
        <p>
          {session.messages[0].content.obj.inspiration.description.substring(
            0,
            50
          )}
          ...
        </p>
      </div>
      <button
        className="session-remove"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete();
        }}
      >
        <CgRemoveR className="remove-icon" />
      </button>
    </motion.div>
  );
};

export default SessionInspirationCard;
