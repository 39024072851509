import React, { useContext } from "react";
import { motion } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5"; // Importation de l'icône
import { GlobalContext } from "../../../GlobalContext";
import axios from "axios";

const InspirationPopup = ({ url, inspiration, onClose }) => {
  const { setActiveMenu, setCurrentSessionCoach } = useContext(GlobalContext);

  console.log(inspiration);

  const sessionObject = (inspiration) => {
    const session = {
      model: {
        type: "variations",
        obj_id: inspiration._id,
      },
      thread_id: null,
      messages: [
        {
          sender: "system",
          type: "Inspiration",
          content: {
            text: "",
            obj: inspiration,
          },
        },
      ],
    };

    return session;
  };

  const createVariationSession = async (inspiration) => {
    try {
      let url_request = `${url}/coach/session/new`;
      const data = sessionObject(inspiration);
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios
        .post(url_request, data, config)
        .then(function (response) {
          setCurrentSessionCoach(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {}
  };

  const sendDataToCoach = async (tab) => {
    await createVariationSession(inspiration);
    localStorage.setItem("activeMenu", tab);
    setActiveMenu(tab);
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <motion.div
        className="popup-content"
        onClick={(e) => e.stopPropagation()} // Empêcher la propagation du clic pour fermer la popup
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* Ajout du bouton Close en haut à droite */}
        <button className="close-button" onClick={onClose}>
          <IoCloseSharp size={20} />
        </button>

        <img
          src={inspiration.inspiration.url_pic}
          className="popup-concept-img"
          alt={inspiration.inspiration.title}
        />
        <h2>{inspiration.inspiration.title}</h2>
        <p>{inspiration.inspiration.description}</p>

        <div className="inspiration-tags">
          <div className="tag archetype">
            {inspiration.inspiration.archetype.name}
          </div>
          <div className="tag content-type">
            {inspiration.inspiration.content_type}
          </div>
          <div className="tag level">
            {inspiration.inspiration.lvl_development}
          </div>
        </div>
        <button
          className="button-send"
          onClick={() => sendDataToCoach("coach")}
        >
          Travailler le concept
        </button>
      </motion.div>
    </div>
  );
};

export default InspirationPopup;
