import React from "react";
import { motion } from "framer-motion";

const InspirationCard = ({ inspiration, openPopup }) => {
  return (
    <motion.button
      id={inspiration._id}
      layout
      initial={{ opacity: 0, boxShadow: "0px 0px 0px #e9e9e9" }}
      animate={{ opacity: 1, boxShadow: "0px 0px 0px #e9e9e9" }}
      exit={{ opacity: 0 }}
      whileHover={{
        translateX: "-2px",
        translateY: "-2px",
        boxShadow: "15px 50px 0px #e9e9e9",
      }}
      transition={{ duration: 0.2 }}
      className="inspiration-post"
      onClick={() => openPopup(inspiration)}
    >
      <h3 className="inspiration-archetype-title">
        {inspiration.inspiration.archetype.name}
      </h3>
      <div className="inspiration-post-background">
        <img
          className="inspiration-img"
          src={inspiration.inspiration.url_pic}
          alt=""
        />

        <div className="shape-gradiant" />
        <div className="inspiration-tag-level">
          {inspiration.inspiration.lvl_development}
        </div>
        <div className="inspiration-info-panel">
          <div className="inspiration-shape" />
          <div className="inpiration-title-panel">
            <h2 className="inspiration-title">
              {inspiration.inspiration.title}
            </h2>
          </div>
        </div>
      </div>
    </motion.button>
  );
};

export default InspirationCard;
