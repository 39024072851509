import React, { useState } from "react";
import { motion } from "framer-motion";

import InfosContractStep from "../components/payments/InfosContractStep";
import EmbedContractStep from "../components/payments/EmbedContractStep";

import axios from "axios";
import { toast } from "sonner";
const URL_API = process.env.REACT_APP_URL_API;
//const URL_API = "http://localhost:5001";

const BeforePayment = () => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [department, setDepartment] = useState("");
  const [country, setCountry] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [nationality, setNationality] = useState(null);

  const [honorificTitle, setHonorificTitle] = useState("monsieur");

  const [plan, setPlan] = useState("several_four");

  const [errors, setErrors] = useState({});

  const [embedSrc, setEmbedSrc] = useState(null);

  const [showInput, setShowInput] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [promoData, setPromoData] = useState(null);

  const handleDiscountCode = async (e) => {
    try {
      const { data } = await axios.post(
        `${URL_API}/payments/check/code-promo`,
        { code, plan }
      );
      setPromoData(data.data);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };
  console.log("Birthday: ", birthday);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!honorificTitle)
      newErrors.honorificTitle = "Veuillez sélectionner une civilité.";

    if (!birthday) newErrors.birthday = "Votre date de naissance est requis.";

    if (!postalCode || !department || !address)
      newErrors.location =
        "L'adresse, le code postal, le département et le pays sont requis.";

    if (!email) newErrors.email = "Un email valide est requis.";
    if (!firstname)
      newErrors.firstname = "Un prénom est requis pour continuer.";
    if (!lastname) newErrors.lastname = "Nom de famille requis pour continuer.";

    if (!nationality) newErrors.nationality = "Votre nationalité est requis.";

    if (Object.keys(newErrors).length > 0) {
      toast.error("Une erreur s'est produite", {
        description:
          "Impossible de passer à l'étape suivante sans avoir fournir correctement toutes les informations nécéssaires. ",
      });
      setErrors(newErrors);
      return;
    }

    setErrors({});

    try {
      const correctedBirthday = birthday
        ? new Date(
            Date.UTC(
              birthday.getFullYear(),
              birthday.getMonth(),
              birthday.getDate()
            )
          )
        : null;

      const { data } = await axios.post(
        `${URL_API}/payments/contract-subscribtion`,
        {
          honorificTitle,
          nationality,
          address,
          postalCode,
          department,
          country,
          birthday: correctedBirthday,
          email,
          firstname,
          lastname,
          plan,
          promoData,
        }
      );

      if (data?.embed_src) {
        setEmbedSrc(data.embed_src);
      } else {
        toast.error("Pas de contrat disponible");
      }
    } catch (error) {
      console.error("Erreur:", error);
      toast.error("Erreur du serveur", {
        description: "Impossible de générer le contrat.",
      });
    }
  };

  return (
    <div className="body">
      <div className="payment-centered">
        <div className="logo-bp">
          <img src="https://cdn.prod.website-files.com/5e5cfad3267af7c3bf7fd935/5ff464626e8d037d4675b501_Logo_InceptionAgency_DarkBlueVersion.png" />
        </div>
        <div className="img-bg-payment">
          <img
            src="https://cdn.prod.website-files.com/5e5cfad3267af7c3bf7fd935/64567d3e7d4faaa37260d164_fond-question-min.png"
            alt="guy-waiting"
          />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {embedSrc ? (
            <EmbedContractStep
              embedSrc={embedSrc}
              plan={plan}
              promoData={promoData}
              url={URL_API}
            />
          ) : (
            <InfosContractStep
              email={email}
              setEmail={setEmail}
              firstname={firstname}
              setFirstname={setFirstname}
              lastname={lastname}
              setLastname={setLastname}
              plan={plan}
              setPlan={setPlan}
              showInput={showInput}
              setShowInput={setShowInput}
              code={code}
              setCode={setCode}
              handleDiscountCode={handleDiscountCode}
              handleSubmit={handleSubmit}
              promoData={promoData}
              address={address}
              setAddress={setAddress}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              department={department}
              setDepartment={setDepartment}
              country={country}
              setCountry={setCountry}
              honorificTitle={honorificTitle}
              setHonorificTitle={setHonorificTitle}
              birthday={birthday}
              setBirthday={setBirthday}
              nationality={nationality}
              setNationality={setNationality}
              errors={errors}
            />
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default BeforePayment;
