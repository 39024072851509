import React, { useState } from "react";
import { DocusealForm } from "@docuseal/react";

import axios from "axios";
import { toast } from "sonner";

const EmbedContractStep = ({
  embedSrc,
  onContractComplete,
  plan,
  promoData,
  url,
}) => {
  const [checkout, setCheckout] = useState("");

  const customCss = `
    #form_container {
      background-color:rgba(241, 241, 241, 0.96);
    }
    
    #minimize_form_button {
      color:rgb(0, 0, 0);
    }
    .white-button{
      border-radius: 3px;
      border: 1px solid black;
      color: black;
    }
    
      .white-button:hover {
      background-color: black; 
      border: 1px solid black;
    }
    
    .base-button {
      background-color: rgb(0, 0, 0);
      border-radius: 3px;
      border: none; 
      transition: all 0.3s ease; 
    }

    #submit_form_button:hover {
      background-color: white; 
      border: 1px solid black;
      color: black; /* Texte en noir au hover */
    }
    
        .base-button:hover {
      background-color: transparent; 
      border: 1px solid black;
      color: black; /* Texte en noir au hover */
    }
    
    #expand_form_button{
      background-color:rgb(0, 0, 0);
    }
    
    #submit_form_button {
      background-color:rgb(0, 0, 0);
    }
  `;

  if (!embedSrc) {
    return <p>Aucun contrat à afficher</p>;
  }

  const handleDocusealComplete = async (docusealData) => {
    if (docusealData?.status === "completed") {
      try {
        const { data } = await axios.post(`${url}/payments/several-times`, {
          data: plan,
          docusealData,
          promoData,
        });

        if (data?.checkout) {
          setCheckout(data.checkout);
        }
      } catch (error) {
        console.error("Erreur:", error);
        toast.error("Erreur lors de la configuration du paiement.");
      }
    }
    if (typeof onContractComplete === "function") {
      onContractComplete(docusealData);
    }
  };

  return (
    <div>
      <DocusealForm
        src={embedSrc}
        customCss={customCss}
        onComplete={handleDocusealComplete}
        sendCopyEmail={false}
        withDownloadButton={false}
        withSendCopyButton={false}
        completedButton={{
          title: "Passer au paiement",
          url: checkout,
        }}
      />
    </div>
  );
};

export default EmbedContractStep;
