import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { AnimatePresence } from "framer-motion";
import SessionInspirationCard from "./sessionInspirationCard";
import { GlobalContext } from "../../../GlobalContext";
import { SocketContext } from "../../../SocketContext";
import { FaMasksTheater } from "react-icons/fa6";

import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";

const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string" || string.length === 0) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ModelConceptToolbox = (props) => {
  const { url, currentModel } = props;
  const [sessions, setSessions] = useState([]);
  const {
    currentSessionCoach,
    setCurrentSessionCoach,
    setMessages,
    setLoading,
    setLoadingType,
    user_archetypes,
  } = useContext(GlobalContext);
  const socket = useContext(SocketContext);

  const getSessionsModelSelected = async () => {
    let url_request = `${url}/coach/session/selected`;
    const data = { type: currentModel.slug };
    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(url_request, data, config);
      setSessions(response.data);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  useEffect(() => {
    if (currentModel) {
      getSessionsModelSelected();
    }
  }, [currentModel, currentSessionCoach]);

  const handleSessionClick = (session) => {
    setCurrentSessionCoach(session);
  };

  const handleSessionDelete = (sessionId) => {
    setSessions(sessions.filter((session) => session._id !== sessionId));
    setCurrentSessionCoach(null);
  };

  const handleCustomizeConcept = async () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "loading", text: "chargement...", sender: "system" },
    ]);
    setLoading(true);
    setLoadingType("concept");

    socket.emit("createVariationConcept", {
      currentModel,
      currentSessionCoach,
    });

    socket.on("responseConceptSession", (response) => {
      console.log("Server response:", response);
      setCurrentSessionCoach(response);
      setLoading(false);
      setLoadingType(null);
    });

    socket.on("error", (error) => {
      console.log("Server error:", error);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.findIndex(
          (msg) => msg.type === "loading"
        );
        if (lastMessageIndex !== -1) {
          updatedMessages[lastMessageIndex] = {
            type: "error",
            text: error.message,
            sender: "system",
          };
        }
        return updatedMessages;
      });
      setLoading(false);
      setLoadingType(null);
    });
  };

  const handleOptionsConcept = async (option) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "loading", text: "", sender: "system" },
    ]);
    setLoading(true);
    setLoadingType("options");

    socket.emit("optionsCoaching", {
      option,
      currentModel,
      currentSessionCoach,
    });

    socket.on("error", (error) => {
      console.log("Server error:", error);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.findIndex(
          (msg) => msg.type === "loading"
        );
        if (lastMessageIndex !== -1) {
          updatedMessages[lastMessageIndex] = {
            type: "error",
            text: error.message,
            sender: "system",
          };
        }
        return updatedMessages;
      });
      setLoading(false);
      setLoadingType(null);
    });
  };
  console.log(user_archetypes);

  return (
    <div className="concept-toolbox-container">
      <div className="session-container">
        <h1 className="variation-title">Vos concepts</h1>
        <AnimatePresence>
          {sessions.map((session) => (
            <SessionInspirationCard
              key={session._id}
              session={session}
              isActive={
                currentSessionCoach && currentSessionCoach._id === session._id
              }
              onClick={() => handleSessionClick(session)}
              onDelete={handleSessionDelete}
            />
          ))}
        </AnimatePresence>
      </div>
      <div className="variation-btn-panel">
        <DropdownMenu className="variation-button">
          <DropdownMenuTrigger asChild>
            <Button variant="outline">Personnaliser le concept</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 ">
            <DropdownMenuLabel>Suggestions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={handleCustomizeConcept}>
                Concept Aléatoire
                <DropdownMenuShortcut>⇧⌘I</DropdownMenuShortcut>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => handleOptionsConcept("concept_custom")}
              >
                Concept Sur-mesure
                <DropdownMenuShortcut>⌘B ou Ctrl+B</DropdownMenuShortcut>
              </DropdownMenuItem>

              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  Concept sur un archetype
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    {user_archetypes && user_archetypes.length > 0 ? (
                      user_archetypes.map((archetype) => (
                        <DropdownMenuItem key={archetype._id}>
                          {capitalizeFirstLetter(archetype.name)}
                          <DropdownMenuShortcut>
                            <FaMasksTheater />
                          </DropdownMenuShortcut>
                        </DropdownMenuItem>
                      ))
                    ) : (
                      <DropdownMenuItem disabled>
                        Aucun archétype disponible
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>More...</DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default ModelConceptToolbox;
