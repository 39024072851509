import React from "react";
import { FaRobot } from "react-icons/fa";

const SystemMessageError = ({ msg }) => {
  return (
    <div className="system-message-container">
      <FaRobot className="system-message-icon" />
      <div className="system-message-error">{msg.text}</div>
    </div>
  );
};

export default SystemMessageError;
