import React, { useEffect, useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import axios from "axios";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

const ComboBoxCountry = ({ setNationality }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedFlag, setSelectedFlag] = useState("");
  const [open, setOpen] = useState(false);

  const nationalityMap = {
    France: "Français",
    Germany: "Allemand",
    Spain: "Espagnol",
    Italy: "Italien",
    Netherlands: "Néerlandais",
    Belgium: "Belge",
    Switzerland: "Suisse",
    Canada: "Canadien",
    "United States": "Américain",
    "United Kingdom": "Britannique",
    Portugal: "Portugais",
    Brazil: "Brésilien",
    China: "Chinois",
    Japan: "Japonais",
    Mexico: "Mexicain",
    Russia: "Russe",
    India: "Indien",
    "South Africa": "Sud-Africain",
    Nigeria: "Nigérian",
    Ghana: "Ghanéen",
    Egypt: "Égyptien",
    Kenya: "Kényan",
    Morocco: "Marocain",
    Tunisia: "Tunisien",
    Algeria: "Algérien",
    Senegal: "Sénégalais",
    "Ivory Coast": "Ivoirien",
    Cameroon: "Camerounais",
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name,flags"
        );

        const filteredCountries = response.data
          .filter((country) => nationalityMap[country.name?.common]) // Garder seulement les pays de la liste
          .map((country) => ({
            label: nationalityMap[country.name.common], // Traduction de la nationalité
            value: nationalityMap[country.name.common],
            flag: country.flags?.png || "", // URL du drapeau
          }))
          .sort((a, b) => a.label.localeCompare(b.label)); // Trier alphabétiquement

        setCountries(filteredCountries);
      } catch (error) {
        console.error("Erreur lors de la récupération des pays :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="min-w-[150px] max-w-[250px] flex items-center justify-between"
        >
          {selectedFlag && (
            <img src={selectedFlag} alt="" className="w-6 h-4 mr-2 rounded" />
          )}
          {selectedNationality || "Sélectionnez une nationalité"}
          <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0">
        <Command>
          <CommandInput placeholder="Rechercher..." />
          <CommandList>
            {loading ? (
              <CommandEmpty>Chargement...</CommandEmpty>
            ) : (
              <>
                <CommandEmpty>Aucune nationalité trouvée.</CommandEmpty>
                <CommandGroup>
                  {countries.map((nat) => (
                    <CommandItem
                      key={nat.value}
                      value={nat.label}
                      onSelect={() => {
                        setSelectedNationality(nat.value);
                        setNationality(nat.value);
                        setSelectedFlag(nat.flag);
                        setOpen(false);
                      }}
                      className="flex items-center"
                    >
                      <img
                        src={nat.flag}
                        alt={nat.label}
                        className="w-6 h-4 mr-2 rounded"
                      />
                      {nat.label}
                      <Check
                        className={cn(
                          "ml-auto",
                          selectedNationality === nat.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ComboBoxCountry;
