import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Toaster } from "sonner";

import Branding from "./pages/Branding";
import BeforePayment from "./pages/BeforePayment";

import { NextUIProvider } from "@nextui-org/react";
import { GlobalProvider } from "./GlobalContext";
import { SocketProvider } from "./SocketContext";

function App() {
  return (
    <NextUIProvider>
      <GlobalProvider>
        <SocketProvider>
          <BrowserRouter>
            <Toaster
              position="top-right"
              richColors // Active des couleurs plus prononcées (optionnel)
              closeButton
            />
            <Routes>
              <Route path="/branding" element={<Branding />} />
              <Route path="/infos-contrat" element={<BeforePayment />} />
            </Routes>
          </BrowserRouter>
        </SocketProvider>
      </GlobalProvider>
    </NextUIProvider>
  );
}

export default App;
