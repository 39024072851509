import React from "react";

const CoachModels = ({ models, onModelClick, selectedModel }) => {
  return (
    <div className="model-container">
      {models.map((data) => (
        <button
          key={data._id}
          className={`model-panel ${
            selectedModel?.slug === data.model.slug ? "selected-model" : ""
          }`}
          onClick={() => onModelClick(data.model)}
        >
          <img
            src={data.model.icon_url}
            alt={"model"}
            className="model-image"
            style={{ backgroundColor: data.model.color }}
          />
          <div className="model-label">{data.model.label}</div>
        </button>
      ))}
    </div>
  );
};

export default CoachModels;
