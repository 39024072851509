import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DropDownProfile from "./ui/DropDownProfile";

const URL = process.env.REACT_APP_URL;

function NavBarApp() {
  const [openProfile, setOpenProfile] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        openProfile &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setOpenProfile(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  return (
    <nav className="navbar-courses">
      <div className="contener-courses">
        <div className="nav-menu-courses">
          <Link to="#" className="brand-2">
            <img
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/5ff4a9ee228a067d3a900e92_BG-LOGO.png"
              className="image-75"
              alt="logo"
            />
          </Link>

          <ul className="list-nav-courses ">
            <li className="list-item-courses">
              <a href={URL} className="link-nav-courses">
                <img
                  src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/5e5cfad37cde6b2240b5cdf2_icon-home.svg"
                  className="icon-nav-courses"
                  alt="home-icon"
                  loading="lazy"
                  style={{ width: "14px" }}
                />
                <div className="txt-nav-link ">ACCUEIL</div>
              </a>
            </li>

            <li className="list-item-courses">
              <a href={URL + "/formation"} className="link-nav-courses">
                <img
                  src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/64406e969b7d2ed0522bb5d2_49944.png"
                  className="icon-nav-courses"
                  alt="courses-icon"
                  loading="lazy"
                  style={{ width: "15px" }}
                />
                <div className="txt-nav-link ">MES COURS</div>
              </a>
            </li>

            <li className="list-item-courses">
              <a href={URL + "/agence"} className="link-nav-courses">
                <img
                  src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/644072c4cf857c734ad1f6aa_ads-icon.png"
                  className="icon-nav-courses"
                  alt="agency-icon"
                  loading="lazy"
                  style={{ width: "14px" }}
                />
                <div className="txt-nav-link ">AGENCE</div>
              </a>
            </li>

            <li className="list-item-courses">
              <Link to="/branding" className="link-nav-courses">
                <img
                  src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/64a05d2bab26229b17e91c26_7398967.png"
                  className="icon-nav-courses"
                  alt="agency-icon"
                  loading="lazy"
                  style={{ width: "16px" }}
                />
                <div className="txt-nav-link ">BRANDING</div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="nav-menu-courses right">
          <ul className="list-nav-courses">
            <li className="list-item-8">
              <div className="div-block-134">
                <div className="text-block-76">{"Bonjour"}</div>
                <div className="text-block-76">{"..."}</div>
                <img
                  className="image-74"
                  loading="lazy"
                  height="20"
                  width="20"
                  src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/631cd1b5c84be3666cd61a6d_Emoji_u1f44b.svg.png"
                  alt="emoji"
                />
              </div>

              <Link
                to="#"
                className="dropdown-wrapper"
                onClick={toggleDropdown}
              >
                <div className="dropdown-toggle">
                  <div className="icon-container"></div>
                  <div className="txt-nav-link white">{"paramètres"}</div>
                </div>
              </Link>

              {openProfile && <DropDownProfile ref={dropDownRef} />}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBarApp;
