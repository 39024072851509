import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import SystemMessageLoading from "./SystemMessageLoading";
import SystemMessageError from "./SystemMessageError";
import { FaRobot } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const SystemMessage = ({ msg }) => {
  const [streamingContent, setStreamingContent] = useState("");

  useEffect(() => {
    if (msg.type === "stream") {
      setStreamingContent(msg.text);
    }
  }, [msg.text]);

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  switch (msg.type) {
    case "loading":
      return <SystemMessageLoading />;
    case "stream":
      return (
        <div className="system-message-container">
          <FaRobot className="system-message-icon" />
          <motion.div
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
            variants={variants}
          >
            <div className="system-text-streaming">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {streamingContent}
              </ReactMarkdown>
            </div>
          </motion.div>
        </div>
      );
    case "error":
      return <SystemMessageError msg={msg} />;
    default:
      return (
        <div className="system-message-container">
          <FaRobot className="system-message-icon" />
          <motion.div
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
            variants={variants}
          >
            <div className="system-text-streaming">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {msg.text}
              </ReactMarkdown>
            </div>
          </motion.div>
        </div>
      );
  }
};

export default SystemMessage;
