import React, { createContext } from "react";
import io from "socket.io-client";

//const URL_API = "http://localhost:5001";
const URL_API = process.env.REACT_APP_URL_API;

const socket = io(URL_API, {
  withCredentials: true, // Cela assure que les cookies sont envoyés
});
export const SocketContext = createContext(socket);

export const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
