import React, { useState } from "react";
import ConceptToolbox from "./ModelConceptToolbox";
import { motion } from "framer-motion";

const CoachModelsToolbox = ({ url, currentModel }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    setShowTooltip(true);
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      {currentModel === null && (
        <div className="model-toolbox-empty-contener">
          <motion.div
            className="toolbox-empty"
            initial={{ border: "1px solid #000000" }}
            animate={{
              border: [
                "1px solid #000000",
                "1px solid #e2e2e2",
                "1px solid #000000",
              ],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "mirror",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <motion.p
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              ☝️
            </motion.p>
            <h3>Choisir un modèle</h3>
            {showTooltip && (
              <div
                className="tooltip-model"
                style={{
                  left: tooltipPosition.x + 10,
                  top: tooltipPosition.y + 10,
                }}
              >
                <strong>Cliquez sur un modèle</strong> de la fenêtre du haut
                afin <strong>d'afficher</strong> les{" "}
                <strong>actions disponibles</strong>.
              </div>
            )}
          </motion.div>
        </div>
      )}
      {currentModel && currentModel.slug === "variations" && (
        <ConceptToolbox url={url} currentModel={currentModel} />
      )}
      {currentModel && currentModel.slug === "freedom" && (
        <div>Freedom Model Toolbox</div>
      )}
    </>
  );
};

export default CoachModelsToolbox;
