import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { FaRobot } from "react-icons/fa";
import { FaPaintBrush } from "react-icons/fa";

const CoachConceptsMessage = ({ concepts }) => {
  return (
    <div className="system-message-container">
      <FaRobot className="system-message-icon" />

      <div className="concepts-container">
        {concepts.map((concept, index) => (
          <div key={index} className="concept-message">
            <div className="concept-message-title-panel">
              <FaPaintBrush className="concept-message-icon" />
              <h1 className="concept-title">{concept.title}</h1>
            </div>
            <div className="concept-content">
              <strong>Description:</strong>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {concept.descr}
              </ReactMarkdown>
              <strong>Purpose:</strong>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {concept.purpose}
              </ReactMarkdown>
              <strong>Marketing Tools:</strong>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {concept.marketing_tools}
              </ReactMarkdown>
              <strong>Plan de vue:</strong>

              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {concept.real_captation}
              </ReactMarkdown>
              <strong>Perspective:</strong>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {concept.perspective}
              </ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachConceptsMessage;
