import React, { useEffect, useState } from "react";

import axios from "axios";
import { motion } from "framer-motion";

import SocialCard from "../../SocialCard";
import { FavoritesProvider } from "./FavoritesContext";

const ARCHETYPE_TAGS = ["archetype_1", "archetype_2", "archetype_3"];

const getTiktoksFavoris = async (url, setFavoris) => {
  let url_request = `${url}/user/favoris/tiktoks`;

  console.log("Execution function callback");
  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(url_request, config)
    .then(function (response) {
      setFavoris(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const FavorisDashboard = ({ url, stats_archetype }) => {
  const [favoris, setFavoris] = useState([]);

  useEffect(() => {
    getTiktoksFavoris(url, setFavoris);
  }, []);

  return (
    <FavoritesProvider
      baseUrl={url}
      onFavoritesUpdate={() => getTiktoksFavoris(url, setFavoris)}
    >
      <div className="content-container">
        <div className="panel-title">
          <h1 className="favoris-title">Liste</h1>
          <h1 className="favoris-title">De vos favoris</h1>
        </div>

        <motion.div
          className="content-posts-panel"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {favoris.map((card) => {
            const recommandation = stats_archetype
              ? stats_archetype.some((stat) =>
                  ARCHETYPE_TAGS.some(
                    (tag) => stat.name === card.tiktok.tags[tag]
                  )
                )
              : false;

            return (
              <SocialCard
                key={card._id}
                id={card._id}
                post={card.tiktok}
                recommand={recommandation}
              />
            );
          })}
        </motion.div>
      </div>
    </FavoritesProvider>
  );
};

export default FavorisDashboard;
