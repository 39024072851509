import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

import { AnimatePresence, motion } from "framer-motion";
import { MdDiscount } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import { cn } from "../../lib/utils";

import Location_suggestions_input from "../ui/Location_suggestions_input";
import ComboBoxCountry from "../ui/ComboBoxCountry";

const InfosContractStep = ({
  email,
  setEmail,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  plan,
  setPlan,
  showInput,
  setShowInput,
  code,
  setCode,
  handleDiscountCode,
  handleSubmit,
  promoData,
  setAddress,
  postalCode,
  setPostalCode,
  department,
  setDepartment,
  country,
  setCountry,
  honorificTitle,
  setHonorificTitle,
  birthday,
  setBirthday,
  nationality,
  setNationality,
  errors,
}) => {
  const [isAutoFilledPostalCode, setIsAutoFilledPostalCode] = useState(false);
  const [isAutoFilledDepartment, setIsAutoFilledDepartment] = useState(false);
  const [isAutoFilledCountry, setIsAutoFilledCountry] = useState(false);

  useEffect(() => {
    if (postalCode) setIsAutoFilledPostalCode(true);
    if (department) setIsAutoFilledDepartment(true);
    if (country) setIsAutoFilledCountry(true);
  }, [postalCode, department, country]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl">Inscription</CardTitle>
        <CardDescription>
          Entrez vos informations pour bénéficier du plusieurs fois sans frais.
        </CardDescription>
      </CardHeader>

      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-6">
            {/* SEXE */}
            <div className="grid gap-2">
              <Label htmlFor="adress">Renseigner votre civilité</Label>

              <RadioGroup defaultValue={honorificTitle} className="flex gap-6">
                <div className="flex items-center space-x-2 px-5 py-1 rounded-lg">
                  <RadioGroupItem
                    value="monsieur"
                    id="monsieur"
                    onClick={() => setHonorificTitle("monsieur")}
                  />
                  <Label htmlFor="men">Monsieur</Label>
                </div>
                <div className="flex items-center space-x-2 px-5 py-1 rounded-lg">
                  <RadioGroupItem
                    value="madame"
                    id="madame"
                    className="checked:ring-2"
                    onClick={() => setHonorificTitle("madame")}
                  />
                  <Label htmlFor="madame">Madame</Label>
                </div>
              </RadioGroup>
              {errors.honorificTitle && (
                <p className="text-xs text-red-600">{errors.honorificTitle}</p>
              )}
            </div>

            {/* NATIONALITE */}
            <div className="grid gap-2">
              <Label htmlFor="nationality">
                Quelle est votre Nationalité ?
              </Label>
              <ComboBoxCountry setNationality={setNationality} />
              {errors.nationality && (
                <p className="text-xs text-red-600">{errors.nationality}</p>
              )}
            </div>

            {/* FIRSTNAME */}
            <div className="grid gap-2">
              <Label htmlFor="firstname">Prénom</Label>
              <Input
                id="firstname"
                type="text"
                placeholder="Veuillez taper votre Prénom"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              {errors.firstname && (
                <p className="text-xs text-red-600">{errors.firstname}</p>
              )}
            </div>

            {/* LASTNAME */}
            <div className="grid gap-2">
              <Label htmlFor="lastname">Nom</Label>
              <Input
                id="lastname"
                type="text"
                placeholder="Nom de famille"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
              {errors.lastname && (
                <p className="text-xs text-red-600">{errors.lastname}</p>
              )}
            </div>

            {/* EMAIL */}
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <p className="text-xs text-red-600">{errors.email}</p>
              )}
            </div>

            {/* BIRTHDAY */}
            <div className="grid gap-2">
              <Label htmlFor="birthday">Votre date de naissance</Label>

              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className={cn(
                      "min-w-[150px] max-w-[180px] justify-start text-left font-normal rounded-lg",
                      !birthday && "text-muted-foreground"
                    )}
                  >
                    <FiCalendar />
                    {birthday ? (
                      format(birthday, "dd/MM/yyyy", { locale: fr })
                    ) : (
                      <span>Choisir une date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="rounded-lg w-auto p-0">
                  <p className="text-sm font-medium mt-2 mx-3">
                    Sélectionner une date
                  </p>
                  <Calendar
                    mode="single"
                    selected={birthday}
                    onSelect={setBirthday}
                    enableDropdowns={true}
                    initialFocus
                    locale={fr}
                  />
                </PopoverContent>
              </Popover>
              {errors.birthday && (
                <p className="text-xs text-red-600">{errors.birthday}</p>
              )}
            </div>

            {/* LOCATION */}
            <div className="grid gap-2">
              <Label htmlFor="adress">Votre adresse de domiciliation</Label>

              <Location_suggestions_input
                setAddress={setAddress}
                setPostalCode={setPostalCode}
                setDepartment={setDepartment}
                setCountry={setCountry}
              />

              <div className="flex gap-6">
                <div className="grid gap-2 max-w-[120px]">
                  <Label htmlFor="zip">Code postal</Label>
                  <Input
                    id="zip"
                    type="text"
                    placeholder=""
                    value={postalCode}
                    onChange={(e) => {
                      setPostalCode(e.target.value);
                      setIsAutoFilledPostalCode(false);
                    }}
                    className={`${
                      isAutoFilledPostalCode ? "bg-yellow-50" : "bg-white"
                    }`}
                  />
                </div>
                <div className="grid gap-2 max-w-[200px]">
                  <Label htmlFor="department">Département</Label>
                  <Input
                    id="department"
                    type="text"
                    placeholder=""
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setIsAutoFilledDepartment(false);
                    }}
                    className={`${
                      isAutoFilledDepartment ? "bg-yellow-50" : "bg-white"
                    }`}
                  />
                </div>

                <div className="grid gap-2 max-w-[180px]">
                  <Label htmlFor="country">Pays</Label>
                  <Input
                    id="country"
                    type="text"
                    placeholder=""
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                      setIsAutoFilledCountry(false);
                    }}
                    className={`${
                      isAutoFilledCountry ? "bg-yellow-50" : "bg-white"
                    }`}
                  />
                </div>
              </div>
              {errors.location && (
                <p className="text-xs text-red-600 mt-1">{errors.location}</p>
              )}
            </div>

            {/* Option Paiement */}
            <div className="grid gap-2">
              <Label htmlFor="payment_type">
                Sélectionnez votre option de paiement
              </Label>

              <Tabs value={plan} className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger
                    className="rounded-lg"
                    value="several_four"
                    onClick={() => !promoData?.valid && setPlan("several_four")}
                    disabled={promoData?.valid}
                  >
                    Paiement en 4 fois
                  </TabsTrigger>
                  <TabsTrigger
                    className="rounded-lg"
                    value="several_twelve"
                    onClick={() =>
                      !promoData?.valid && setPlan("several_twelve")
                    }
                    disabled={promoData?.valid}
                  >
                    Paiement en 12 fois
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>

            <div className="flex items-center gap-4">
              <AnimatePresence mode="wait">
                {promoData?.valid ? (
                  <div className="flex items-center gap-2 ">
                    <motion.div
                      key="validPromo"
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 10 }}
                      className="flex items-center gap-2 rounded-lg bg-green-100 px-3 py-2 border border-green-500 text-green-800"
                    >
                      <MdDiscount />
                      <span className="font-semibold uppercase">{code}</span>
                    </motion.div>

                    <p className="text-xs text-green-800">
                      {promoData.percent_off}% de réduction appliquée
                    </p>
                  </div>
                ) : !showInput ? (
                  <motion.div key="showButton">
                    <Button
                      type="button"
                      className="bg-[#ff105c] text-white border border-[#ff1044] hover:bg-transparent hover:text-[#ff105c] hover:border-[#ff105c] transition-colors duration-200 rounded-lg"
                      onClick={() => setShowInput(true)}
                    >
                      Ajouter un code promotionnel
                    </Button>
                  </motion.div>
                ) : (
                  <div>
                    <motion.div
                      key="inputField"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="flex items-center gap-2 rounded-lg"
                    >
                      <Input
                        type="text"
                        placeholder="Entrez votre code promo"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="uppercase placeholder:normal-case"
                      />
                      <AnimatePresence mode="wait">
                        {code.length > 0 && (
                          <motion.div
                            key="applyButton"
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 10 }}
                          >
                            <Button
                              type="button"
                              className="border border-black hover:bg-transparent hover:text-black hover:border-black hover:shadow-none transition-colors duration-200 rounded-lg"
                              onClick={handleDiscountCode}
                            >
                              Appliquer
                            </Button>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                    <AnimatePresence mode="wait">
                      {promoData?.valid === false && (
                        <motion.p
                          key="errorMessage"
                          initial={{ opacity: 0, y: -5 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -5 }}
                          className="mt-[5px] text-xs text-red-600"
                        >
                          {promoData.message}
                        </motion.p>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </AnimatePresence>
            </div>

            <Button type="submit" className="w-full rounded-lg">
              Suivant
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default InfosContractStep;
