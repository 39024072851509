import React, { useContext, useState, useRef, useEffect } from "react";
import { GlobalContext } from "../../../GlobalContext";
import InspirationMessage from "./CoachInspirationMessage";
import ConceptsMessage from "./CoachConceptsMessage";
import ModelPanel from "./CoachModels";
import ModelConceptToolbox from "./CoachModelsToolbox";
import SystemMessage from "./system_messages/SystemMessage";
import { SocketContext } from "../../../SocketContext";

import { FaRobot } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaStop } from "react-icons/fa6";

import axios from "axios";

import { motion } from "framer-motion";

const CoachDashboard = (props) => {
  const {
    currentSessionCoach,
    setCurrentSessionCoach,
    messages,
    setMessages,
    loading,
    setLoading,
    loadingType,
    setLoadingType,
    userID,
  } = useContext(GlobalContext);
  const socket = useContext(SocketContext);

  const [inputMessage, setInputMessage] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const textareaRef = useRef(null);
  const chatPanelRef = useRef(null);
  const bottomRef = useRef(null);

  const formatSendingMessage = (msg, type, sender) => {
    return {
      sender: sender,
      type: type,
      content: {
        obj: {
          text: msg,
          obj: null,
        },
      },
    };
  };

  const formatReceivedMessage = (msg, sender) => {
    switch (msg.type) {
      case "concept":
        return {
          type: msg.type,
          concept: msg.content.obj,
          sender: sender,
        };
      case "Inspiration":
        return {
          type: msg.type,
          inspiration: msg.content.obj,
          sender: sender,
        };
      case "text":
        return {
          type: msg.type,
          text: msg.content.obj.text,
          sender: sender,
        };
      default:
        return null;
    }
  };

  const handleStopRequest = async () => {
    if (loadingType === "concept") {
      socket.emit("cancelConceptRequest");
    } else if (loadingType === "conversation") {
      socket.emit("cancelConversationRequest");
    }
    setLoading(false);
    setLoadingType(null);
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessageIndex = updatedMessages.findIndex(
        (msg) => msg.type === "loading"
      );
      if (lastMessageIndex !== -1) {
        updatedMessages[lastMessageIndex] = {
          type: "error",
          text: "Votre requête a bien été annulée.",
          sender: "system",
        };
      }
      return updatedMessages;
    });
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      const newMessage = {
        type: "text",
        text: inputMessage,
        sender: "user",
      };

      setMessages((prevMessages) => [
        ...prevMessages,
        newMessage,
        { type: "loading", text: "", sender: "system" },
      ]);
      setInputMessage("");

      const formattedMessage = formatSendingMessage(
        inputMessage,
        "text",
        "user"
      );
      const updatedSession = { ...currentSessionCoach };
      updatedSession.messages.push(formattedMessage);
      setCurrentSessionCoach(updatedSession);
      setLoading(true);
      setLoadingType("conversation");

      socket.emit("startConversation", { selectedModel, updatedSession });

      socket.on("error", (error) => {
        console.error("Error sending message:", error);
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.findIndex(
            (msg) => msg.type === "loading"
          );
          if (lastMessageIndex !== -1) {
            updatedMessages[lastMessageIndex] = {
              type: "error",
              text: error.message,
              sender: "system",
            };
          }
          return updatedMessages;
        });
        setLoading(false);
        setLoadingType(null);
      });
    }
  };

  const handleModelClick = async (model) => {
    setSelectedModel(model);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.altKey && !e.metaKey) {
      if (loading) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      handleSendMessage();
    } else if (e.key === "Enter" && (e.altKey || e.metaKey)) {
      e.preventDefault();
      setInputMessage(inputMessage + "\n");
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  const selectModelBySlug = (model) => {
    if (models.length > 0) {
      const result_model = models.find((m) => m.model.slug === model.type);
      if (result_model) {
        setSelectedModel(result_model.model);
        return model;
      } else {
        console.log(`Model with slug ${model.type} not found.`);
        return null;
      }
    } else {
      console.log("No models available.");
      return null;
    }
  };

  useEffect(() => {
    if (currentSessionCoach) {
      socket.emit("registerSession", currentSessionCoach._id);

      if (loading == true) {
        if (loadingType === "concept") {
          socket.emit("cancelConceptRequest");
          setLoading(false);
          setLoadingType(null);
        } else if (loadingType === "conversation") {
          socket.emit("cancelConversationRequest");
          setLoading(false);
          setLoadingType(null);
        }
      }

      setMessages([]);
      selectModelBySlug(currentSessionCoach.model);

      const newMessages = currentSessionCoach.messages
        .map((msg) => formatReceivedMessage(msg, msg.sender))
        .filter((msg) => msg !== null);

      setMessages(newMessages);
    } else {
      setMessages([]);
    }
  }, [models, currentSessionCoach?._id]);

  useEffect(() => {
    if (currentSessionCoach && messages.length > 0) {
      const newMessages = currentSessionCoach.messages
        .map((msg) => formatReceivedMessage(msg, msg.sender))
        .filter((msg) => msg !== null);

      const localLastMessage = messages[messages.length - 1];
      const serverLastMessage = newMessages[newMessages.length - 1];
      if (localLastMessage.type === "loading" && loading === false) {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = serverLastMessage;
          return updatedMessages;
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    socket.on("streamingData", (data) => {
      console.log("Enter in streamingDAta");
      console.log("currentSession ->", currentSessionCoach);
      console.log("message -> ", messages);

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];

        const loadingMessageIndex = updatedMessages.findIndex(
          (msg) => msg.type === "loading" || msg.type === "stream"
        );

        if (loadingMessageIndex !== -1) {
          updatedMessages[loadingMessageIndex].type = "stream";
          updatedMessages[loadingMessageIndex].text += data;
        }
        return updatedMessages;
      });
    });

    socket.on("streamingEnd", (data) => {
      console.log("Enter in streamingEnd");
      console.log("currentSession ->", currentSessionCoach);
      console.log("message -> ", messages);

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const streamMessageIndex = updatedMessages.findIndex(
          (msg) => msg.type === "stream"
        );
        if (streamMessageIndex !== -1) {
          updatedMessages[streamMessageIndex].type = "text";
        }
        return updatedMessages;
      });
      setCurrentSessionCoach(data);
      setLoading(false);
      setLoadingType(null);
    });

    return () => {
      socket.off("streamingData");
      socket.off("streamingEnd");
    };
  }, []);

  const getMessageComponent = (msg) => {
    if (msg.sender === "system" && msg.inspiration) {
      return <InspirationMessage inspiration={msg.inspiration.inspiration} />;
    } else if (msg.sender === "system" && msg.type === "concept") {
      return <ConceptsMessage concepts={msg.concept.obj.concepts} />;
    } else if (msg.sender === "user") {
      return <div>{msg.text}</div>;
    } else if (msg.sender === "system") {
      return <SystemMessage msg={msg} />;
    } else {
      return (
        <div className="system-message-container">
          <FaRobot className="system-message-icon" />
          <div>{msg.text}</div>
        </div>
      );
    }
  };

  const getCoachModel = async () => {
    let url_request = `${props.url}/coach/get/model`;
    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url_request, config);
      setModels(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    getCoachModel();

    if (userID) {
      socket.emit("register", userID);
    }
  }, []);

  return (
    <motion.div
      className="content-container coach-custom"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: [0, 0.71, 0.91, 1.21],
      }}
    >
      <div className="coach-grid-structure">
        <div className="chat-wrapper">
          <div className="chat-content-panel" ref={chatPanelRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message-role ${
                  msg.sender === "user" ? "user-message" : "system-message"
                }`}
              >
                <div className="chat-message-content">
                  {getMessageComponent(msg)}
                </div>
              </div>
            ))}
            <div ref={bottomRef}></div>
          </div>
          <div className="chat-input-wrapper">
            <div className="chat-input-box">
              <textarea
                ref={textareaRef}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                className="chat-input"
                placeholder="Une question en tête ?"
                rows="1"
                disabled={!messages || messages.length === 0}
              />
              <button
                onClick={loading ? handleStopRequest : handleSendMessage}
                className="send-button"
                disabled={!messages || messages.length === 0} // Désactive si messages est vide ou null
              >
                {loading ? (
                  <FaStop className="enter-message-icon" />
                ) : (
                  <FaPlay className="enter-message-icon" />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="tool-container">
          <div className="coach-model-wrapper">
            <ModelPanel
              models={models}
              onModelClick={handleModelClick}
              selectedModel={selectedModel}
            />
          </div>
          <div className="coach-options-wrapper">
            <ModelConceptToolbox url={props.url} currentModel={selectedModel} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CoachDashboard;
