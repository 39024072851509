import React from "react";
import { motion } from "framer-motion";
import { FaRobot } from "react-icons/fa";

const SystemMessageLoading = () => {
  return (
    <div
      className="loading-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <FaRobot className="system-message-icon" />
      <motion.div
        className="loading-circle"
        initial={{ scale: 0.5 }}
        animate={{ scale: [0.5, 1, 0.5] }}
        transition={{
          duration: 1,
          ease: "easeInOut",
          repeat: Infinity,
        }}
        style={{
          width: 15,
          height: 15,
          borderRadius: "50%",
          backgroundColor: "#000",
        }}
      />
      <motion.div
        style={{ marginLeft: 10 }}
        animate={{ color: ["#c6c6c6", "#ebebeb", "#dddddd", "#c6c6c6"] }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      >
        Ça arrive 2 petites secondes...
      </motion.div>
    </div>
  );
};

export default SystemMessageLoading;
